export const menuItems = [
  {
    title: "Accueil",
    url: "/",
  },
  {
    title: "Services",
    url: "/Service",
  },
  {
    title: "Rendez-Vous",
    url: "/Rendez-Vous",
  },
  {
    title: "Contactez-Nous ",
    url: "/Contactez-Nous ",
  },
];
